/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { Rating } from "react-custom-rating-component";
import { MdCancel } from "react-icons/md";
import { FaCircleCheck } from "react-icons/fa6";
import GGChip from "../../GGChip";
import { DateColumn } from "../Templates/ColumnTemplates/ReviewsTemplates";
import { formattedPrice, generateAlphanumericId } from "utils";

export const PRODUCT_COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
    style: { minWidth: "200px" },
    Cell: ({ value, row }) => (
      <div className="flex gap-1 items-center capitalize">
        <div className="w-[40px] h-[40px] rounded mr-2">
          <img
            src={row?.original?.mainImage}
            alt={value}
            className="w-full rounded h-full"
          />
        </div>
        {value}
      </div>
    ),
  },
  {
    Header: "Category",
    accessor: "category",
    Cell: ({ value }) => (
      <div className="flex gap-1 flex-wrap max-w-[180px]">
        {value ? (
          <GGChip bgColor="bg-linear" textColor="text-white">
            <span className="text-xs font-normal capitalize">{value}</span>
          </GGChip>
        ) : null}
      </div>
    ),
  },
  {
    Header: "Price",
    accessor: "price",
    Cell: ({ cell: { value } }) => (
      <div className="capitalize">{formattedPrice(value, "UGX")}</div>
    ),
  },
  {
    Header: "Quantity(Pieces)",
    accessor: "quantityInStock",
    style: { textAlign: "center" },
    Cell: ({ value, row }) => (
      <div className="flex items-center gap-2 justify-center">
        {value}
        <GGChip
          bgColor={value === "outOfStock" ? "bg-[#fceff3]" : "bg-[#ebf4eb]"}
          textColor={
            value === "outOfStock" ? "text-[#dd4470]" : "text-[#3c933c]"
          }
        >
          {row?.original?.stockStatus === "outOfStock"
            ? "Out of Stock"
            : "In Stock"}
        </GGChip>
      </div>
    ),
  },
  {
    Header: "Status",
    accessor: "stockStatus",
    Cell: ({ row }) => (
      <div className="flex items-center gap-2">
        <GGChip
          bgColor={row?.original?.isDraft ? "bg-signal-error" : "bg-green-600"}
          textColor={row?.original?.isDraft ? "text-white" : "text-white"}
        >
          {row?.original?.isDraft ? "Draft" : "Published"}
        </GGChip>
        {row?.original?.isPopular && (
          <GGChip bgColor="bg-linear" textColor="text-white">
            <span className="text-xs font-normal">Popular</span>
          </GGChip>
        )}
      </div>
    ),
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
  },
];

export const DASHBOARD_PRODUCT_COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
    style: { minWidth: "200px" },
    Cell: ({ value, row }) => (
      <div className="flex gap-1 items-center capitalize">
        <div className="w-[40px] h-[40px] rounded mr-2">
          <img
            src={row?.original?.mainImage}
            alt={value}
            className="w-full rounded h-full"
          />
        </div>
        {value}
      </div>
    ),
  },
  {
    Header: "Category",
    accessor: "category",
    Cell: ({ value }) => (
      <div className="flex gap-1 flex-wrap max-w-[180px]">
        {value ? (
          <GGChip bgColor="bg-linear" textColor="text-white">
            <span className="text-xs font-normal capitalize">{value}</span>
          </GGChip>
        ) : null}
      </div>
    ),
  },
  {
    Header: "Price",
    accessor: "price",
    Cell: ({ cell: { value } }) => (
      <div className="capitalize">{formattedPrice(value, "UGX")}</div>
    ),
  },
  {
    Header: "Status",
    accessor: "stockStatus",
    Cell: ({ value }) => (
      <GGChip
        bgColor={value === "outOfStock" ? "bg-[#fceff3]" : "bg-[#ebf4eb]"}
        textColor={value === "outOfStock" ? "text-[#dd4470]" : "text-[#3c933c]"}
      >
        {value === "outOfStock" ? "Out of Stock" : "In Stock"}
      </GGChip>
    ),
  },
];

export const CATEGORIES_COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ value, row }) => (
      <div className="flex gap-1 items-center">
        <div className="w-[40px] h-[40px] rounded mr-2">
          <img
            src={row?.original?.image}
            alt={value}
            className="w-full rounded h-full"
          />
        </div>
        {value}
      </div>
    ),
  },
  {
    Header: "Status",
    accessor: "isHidden",
    Cell: ({ row }) => (
      <label
        className="inline-flex items-center me-5 cursor-pointer"
        title={row?.original?.isHidden ? "Invisible on client app" : ""}
      >
        <input
          type="checkbox"
          value=""
          className="sr-only peer"
          defaultChecked={!row?.original?.isHidden}
          disabled
        />
        <div className="relative w-9 h-5 bg-[#ccc] rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-white after:border after:rounded-full after:h-4 after:w-4 after:transition-all  peer-checked:bg-green-600"></div>
        <span className="ms-1 text-sm font-medium text-gray-900 dark:text-gray-300">
          {row?.original?.isHidden ? "Invisible" : "Visible"}
        </span>
      </label>
    ),
  },
  {
    Header: "Sub Categories",
    accessor: "subCategories",
    Cell: ({ value }) => (
      <div className="flex gap-1 flex-wrap">
        {value?.map((item) => (
          <GGChip
            key={Math.random()}
            className="flex items-center gap-1"
            variant="outlined"
          >
            {item?.image?.length > 0 && (
              <div className="w-6 h-6 rounded-full">
                <img
                  src={item?.image}
                  alt={item?.name}
                  className="w-full h-full rounded-full"
                />
              </div>
            )}
            <div className="text-xs font-normal capitalize">{item?.name}</div>
          </GGChip>
        ))}
      </div>
    ),
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    sortable: true,
  },
];

export const SUB_CATEGORIES_COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ value, row }) => (
      <div className="flex gap-1 items-center">
        <div className="w-[40px] h-[40px] rounded mr-2">
          <img
            src={row?.original?.image}
            alt={value}
            className="w-full rounded h-full"
          />
        </div>
        {value}
      </div>
    ),
  },
  {
    Header: "Status",
    accessor: "isHidden",
    Cell: ({ row }) => (
      <label
        className="inline-flex items-center me-5 cursor-pointer"
        title={row?.original?.isHidden ? "Invisible on client app" : ""}
      >
        <input
          type="checkbox"
          value=""
          className="sr-only peer"
          defaultChecked={!row?.original?.isHidden}
          disabled
        />
        <div className="relative w-9 h-5 bg-[#ccc] rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-white after:border after:rounded-full after:h-4 after:w-4 after:transition-all  peer-checked:bg-green-600"></div>
        <span className="ms-1 text-sm font-medium text-gray-900 dark:text-gray-300">
          {row?.original?.isHidden ? "Invisible" : "Visible"}
        </span>
      </label>
    ),
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    sortable: true,
  },
];

export const CATEGORY_COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ value, row }) => (
      <div className="flex gap-1 items-center capitalize">
        <div className="w-[40px] h-[40px] rounded mr-2">
          <img
            src={row?.original?.categoryImage}
            alt={value}
            className=" rounded w-[40px] h-[40px]"
          />
        </div>
        {value}
      </div>
    ),
  },
  { Header: "Description", accessor: "description" },
  {
    Header: "SubCategories",
    accessor: "subCategories",
    Cell: ({ value }) => (
      <div className="flex gap-1 flex-wrap max-w-[250px]">
        {value?.map((item) => (
          <GGChip
            // bgColor="bg-linear"
            textColor="text-white"
            key={Math.random()}
            variant="outlined"
          >
            <span className="text-xs font-normal capitalize">{item.name}</span>
          </GGChip>
        ))}
      </div>
    ),
  },
];

export const ORDER_COLUMNS = [
  {
    Header: "Order ID",
    accessor: "orderNumber",
    style: { alignText: "center" },
  },
  {
    Header: "Customer",
    accessor: "owner",
    Cell: ({ value, row }) => {
      return (
        <div>
          <p>{row?.original?.shippingAddress?.shippingName}</p>
          <a href={`mailto:${value}`} className="hover:text-linear">
            {value}
          </a>
        </div>
      );
    },
  },
  {
    Header: "Quantity",
    style: { textAlign: "center" },
    accessor: "quantity",
  },
  {
    Header: "Amount",
    accessor: "amount",
    Cell: ({ value }) => (
      <div className="capitalize">{formattedPrice(value, "UGX")}</div>
    ),
  },
  {
    Header: "Status",
    accessor: "orderStatus",
    Cell: ({ value }) => (
      <GGChip
        bgColor={
          value === "COMPLETED"
            ? "bg-[#ebf4eb]"
            : "bg-warningBg group-hover:bg-warningText"
        }
        textColor={
          value === "COMPLETED"
            ? "text-[#3c933c]"
            : "text-warningText group-hover:text-white"
        }
      >
        {value}
      </GGChip>
    ),
  },
  {
    Header: "Order Date",
    accessor: "createdAt",
  },
];

export const SHOPS_COLUMN = [
  {
    Header: "Name",
    accessor: "name",
    style: { minWidth: "200px" },
    Cell: ({ value, row }) => (
      <div className="flex gap-1 items-center capitalize">
        <div className="  mr-2">
          <img
            src={row?.original?.image}
            alt={value}
            className="w-[40px] h-[40px] rounded-md"
          />
        </div>
        {value}
      </div>
    ),
  },
  {
    Header: "Description",
    accessor: "description",
    style: { maxWidth: "200px" },
    Cell: ({ value }) => <p className="line-clamp-1">{value}</p>,
  },
  { Header: "Location", accessor: "location" },
  { Header: "City", accessor: "city" },
  {
    Header: "Categories",
    accessor: "categories",
    Cell: ({ value }) => (
      <div className="flex gap-1 flex-wrap max-w-[180px]">
        {value?.map((item) => (
          <GGChip
            bgColor="bg-linear"
            textColor="text-white"
            key={Math.random()}
          >
            <span className="text-xs font-normal capitalize">{item.name}</span>
          </GGChip>
        ))}
      </div>
    ),
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
  },
  {
    Header: "Verified",
    accessor: "isVerified",
    style: { textAlign: "center" },
    Cell: ({ value }) => {
      return (
        <div className="flex justify-center items-center">
          {value ? (
            <FaCircleCheck
              style={{ color: "green", width: "20px", height: "20px" }}
            />
          ) : (
            <MdCancel style={{ color: "red", width: "24px", height: "24px" }} />
          )}
        </div>
      );
    },
  },
];

export const COMMISION_COLUMNS = [
  { Header: "ID", accessor: "id" },
  { Header: "Name", accessor: "name" },
  { Header: "Amount", accessor: "amount_ugx" },
  { Header: "Date", accessor: "date" },
  { Header: "Status", accessor: "status" },
];

export const USERS_COLUMNS = [
  { Header: "ID", accessor: "userId" },
  {
    Header: "Name",

    Cell: ({ row }) => (
      <span>{row?.original?.firstName + " " + row?.original?.lastName}</span>
    ),
  },
  { Header: "User Name", accessor: "userName" },
  { Header: "Email", accessor: "email" },
  { Header: "Role", accessor: "role" },
  {
    Header: "Registered Date",
    accessor: "createdAt",
  },

  {
    Header: "Verified",
    accessor: "isVerified",
    Cell: ({ value }) => {
      return (
        <div className="flex justify-center items-center">
          {value ? (
            <FaCircleCheck
              style={{ color: "green", width: "20px", height: "20px" }}
            />
          ) : (
            <MdCancel style={{ color: "red", width: "24px", height: "24px" }} />
          )}
        </div>
      );
    },
  },
];

export const DELIVERY_LOCATIONS_COLUMNS = [
  { Header: "Region", accessor: "region" },
  { Header: "Area", accessor: "area" },
  {
    Header: "Cost",
    accessor: "cost",
    Cell: ({ value }) => (
      <div className="capitalize">{formattedPrice(value, "UGX")}</div>
    ),
  },
];

export const REVIEW_COLUMNS = [
  {
    Header: "ID",

    Cell: () => <p>{generateAlphanumericId(6)}</p>,
  },

  {
    Header: "Name",
    // accessor: "userName",
    Cell: ({ row }) => <p>{row.original.userName}</p>,
  },
  { Header: "Product", accessor: "productId" },
  {
    Header: "Rating",
    accessor: "rating",
    Cell: ({ value }) => (
      <div className="flex items-center gap-4 font-poppins font-medium">
        <Rating defaultValue={value} readOnly />
        {value}
      </div>
    ),
  },
  {
    Header: "Review",
    accessor: "comment",
    Cell: ({ value }) => (
      <div className="flex flex-1 gap-5 border border-linear h-20 rounded-md  max-w-[480px] p-4 overflow-hidden">
        {value}
      </div>
    ),
  },
  {
    Header: "Date",
    accessor: "createdAt",
    Cell: ({ value }) => <DateColumn name={value} />,
  },
];

export const SINGLE_ORDER_COLUMNS = [
  {
    Header: "Product",
    accessor: "product_name",
    Cell: ({ value, row }) => (
      <div className="flex gap-1 items-center capitalize">
        <div className="w-[40px] h-[40px] rounded mr-2">
          <img
            src={row?.original?.product?.mainImage}
            alt={value}
            className="w-full rounded h-full"
          />
        </div>
        {row?.original?.product?.name}
      </div>
    ),
  },
  { Header: "Store Name", accessor: "storeName" },
  { Header: "Quantity", accessor: "quantity" },
  {
    Header: "Size",
    accessor: "size",
    Cell: ({ value }) => (
      <div className="uppercase h-8 w-8 border-2 border-primary bg-[#5e5e5e]/30 rounded-full shadow-lg flex items-center justify-center">
        {value || "-"}
      </div>
    ),
  },
  {
    Header: "Color",
    accessor: "color",
    Cell: ({ value }) => (
      <div
        title="White"
        className="capitalize h-8 w-8 rounded-full shadow-lg"
        style={{ backgroundColor: value }}
      ></div>
    ),
  },

  {
    Header: "Price",
    accessor: "price",

    Cell: ({ row }) => (
      <div className="capitalize">
        {formattedPrice(row?.original?.product?.price, "UGX")}
      </div>
    ),
  },
  {
    Header: "Total",
    accessor: "total",
    Cell: ({ row }) => (
      <div className="capitalize">
        {formattedPrice(
          row?.original?.product?.price * row?.original?.quantity,
          "UGX",
        )}
      </div>
    ),
  },
];
