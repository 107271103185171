import React, { useEffect, useState } from "react";
import { type ToastContent, toast } from "react-toastify";
import { BreadCrumb, SectionTitle, Spinner } from "components/Common";
import { GGButton } from "components/UI";
import { Formik } from "formik";
import {
  useEditBannerMutation,
  useLazyGetBannerQuery,
  useUploadImageMutation,
} from "store/api";
import { getCloudFlareImageUrl } from "utils";
import { useNavigate, useParams } from "react-router-dom";
import { useNewBannerMutation } from "store/api/requests/banners/newBannerApiSlice";
import BannerForm from "./BannerForm";

const AddBanner = ({ isEdit }: { isEdit?: boolean }) => {
  const { bannerId } = useParams();
  const navigate = useNavigate();
  const [uploadImage, { isLoading: uploadImageIsLoading }] =
    useUploadImageMutation();
  const [newBanner, { isLoading }] = useNewBannerMutation();
  const [editBanner, { isLoading: isEditing }] = useEditBannerMutation();
  const [getBanner, { isFetching }] = useLazyGetBannerQuery();
  const [banner, setBanner] = useState<any>({});

  const onSubmitHandler = async (values: any) => {
    if (isEdit) {
      if (values.image instanceof Blob && !values.imageLink) {
        const imageUploadRes = await uploadImage(values.image).unwrap();
        values.image = getCloudFlareImageUrl(imageUploadRes?.result);
      }
      await editBanner({
        id: bannerId,
        title: values.title,
        description: values.description,
        type: values.type,
        categoryId: values.categoryId,
        image: values.imageLink || values.image,
      })
        .unwrap()
        .then(() => {
          toast.success("Banner edited successfully");
          navigate("../banners");
        });
    } else {
      try {
        let image = "";
        if (!values.imageLink) {
          const res = await uploadImage(values.image).unwrap();
          image = getCloudFlareImageUrl(res?.result);
        }

        const bannerData = {
          image: values.imageLink || image,
          title: values.title,
          description: values.description,
          type: values.type,
          categoryId: values.categoryId,
        };

        await newBanner(bannerData).unwrap();

        toast.success("Banner added successfully");
        navigate("../banners");
      } catch (error: any) {
        toast.error(error.message as ToastContent<unknown>);
      }
    }
  };

  const fetchData = async () => {
    try {
      const fetchedData = await getBanner({ id: bannerId }).unwrap();
      setBanner(fetchedData.banner);
    } catch (error: any) {
      toast.error(error.message as ToastContent<unknown>);
    }
  };

  useEffect(() => {
    if (isEdit && bannerId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchData();
    }
  }, [isEdit]);

  return (
    <div>
      <BreadCrumb />
      <div className="card">
        <SectionTitle title="Add OnBoard Screen" />
        <Formik
          enableReinitialize
          initialValues={{
            title: banner?.title || "",
            description: banner?.description || "",
            type: banner?.type || "",
            categoryId: banner?.categoryId || "",
            image: banner?.image || null,
            imageLink: null,
          }}
          //   validationSchema={AddOnBoardScreenSchema}
          onSubmit={async (values, { resetForm }) => {
            await onSubmitHandler(values);
            resetForm();
            navigate("../banners");
          }}
        >
          {({ handleSubmit, values, setFieldValue, dirty, isValid }) => (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <form onSubmit={handleSubmit}>
                <BannerForm setFieldValue={setFieldValue} values={values} />
                <GGButton
                  type="submit"
                  disable={
                    isLoading ||
                    uploadImageIsLoading ||
                    isEditing ||
                    !dirty ||
                    !isValid
                  }
                >
                  {isLoading || uploadImageIsLoading || isEditing ? (
                    <Spinner />
                  ) : isEdit ? (
                    "Edit Banner "
                  ) : (
                    "Add Banner"
                  )}
                </GGButton>
              </form>
              <div className="flex flex-col justify-center w-full mx-auto">
                <h4 className="font-semibold text-md text-center">
                  App Preview
                </h4>
                <div className="flex justify-center mt-2">
                  <div
                    className={`h-[500px] w-[250px] rounded-b-2xl overflow-hidden group cursor-pointer bg-[#f5f5f5] relative max-sm:w-full pointer-events-none select-none ${isFetching ? "animate-pulse" : ""}`}
                  >
                    <div
                      style={{
                        backgroundColor: "transparent",
                        backgroundPosition: "center",
                        backgroundSize:
                          values?.image === null ? "cover" : "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundImage: `url(${values?.image !== null ? (values.image instanceof Blob ? URL.createObjectURL(values?.image as Blob | MediaSource) : values?.imageLink || values?.image) : "https://via.placeholder.com/150"})`,
                      }}
                      className="h-[40%] w-full flex items-center justify-center"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddBanner;
