import React, { useEffect, useState } from "react";
import { FaRegImage } from "react-icons/fa";
import Button from "../Button";
import { RiArrowRightLine, RiDeleteBin5Fill } from "react-icons/ri";
import Modal from "../Modal";
import { GGButton } from "components/UI";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "store/slices";

const ImageUpload = ({
  imageUrl = "",
  fieldName,
  setFieldValue,
  label,
  note,
  clearImage,
  linkName,
}: {
  fieldName: string;
  setFieldValue: any;
  imageUrl?: string;
  label?: string;
  note?: string;
  clearImage?: boolean;
  linkName?: string;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [value, setValue] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const user = useSelector(selectUser);

  const onChangeHandler = (event: { target: any }) => {
    setFieldValue(linkName, null);
    const imageLink = event.target.files[0]
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        URL.createObjectURL(event.target.files[0])
      : "";
    setImage(imageLink);
    setFieldValue(fieldName, event.target.files[0]);
  };

  const dragHandler = (event: {
    target: any;
    dataTransfer: any;
    preventDefault: () => void;
  }) => {
    event.preventDefault();
    event.target.files = event.dataTransfer.files;
  };
  const dropHandler = (event: { target: any; preventDefault: () => void }) => {
    event.preventDefault();
    onChangeHandler(event);
  };

  useEffect(() => {
    if (clearImage) {
      setImage("");
    }
  }, [clearImage]);

  const hasImage = image?.length > 0 || imageUrl?.length > 0;

  const onDelete = (e: any) => {
    e.stopPropagation();
    setImage("");
    setFieldValue(fieldName, null);
    setFieldValue(linkName, null);
  };

  const onAddLinkClick = () => {
    setImage(value);
    setFieldValue(linkName, value);
    setModalOpen(false);
    setValue("");
  };

  return (
    <div className="flex flex-col gap-2 w-full overflow-hidden">
      {label && (
        <span className="field-label text-textColor">
          {label}{" "}
          {note && (
            <span
              className="text-muted"
              style={{
                fontSize: "10px",
              }}
            >
              ({note})
            </span>
          )}
        </span>
      )}

      <label
        className="group media-dropzone 2xl:col-span-2 cursor-pointer relative"
        htmlFor={fieldName}
        style={{
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          backgroundImage: `url(${image || imageUrl})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
        id={`img-${Math.random()}`}
        onDrag={dragHandler}
        onDrop={dropHandler}
      >
        {hasImage && (
          <div className="absolute top-0 right-0 opacity-0 group-hover:opacity-100 z-10">
            <Button
              type="button"
              variant="secondary"
              className="text-red-500"
              onClick={onDelete}
            >
              <RiDeleteBin5Fill />
            </Button>
          </div>
        )}
        <input
          accept="image/jpeg,image/png,image/gif,image/bmp,image/webp,image/svg+xml"
          type="file"
          id={fieldName}
          name={fieldName}
          hidden
          onChange={onChangeHandler}
        />
        <div
          className={`${hasImage ? "opacity-0 group-hover:opacity-100 group-hover:bg-white/30" : ""} p-3 flex flex-col items-center gap-2.5 space-y-2 rounded-md`}
        >
          <FaRegImage className="text-[20px] text-[#AEAEAE]" />
          <p className="font-bold font-poppins text-sm text-primary">
            {hasImage ? "Change Image" : "Browse Image"}
          </p>
          {user?.role === "admin" && (
            <>
              <p className="text-xs font-light">OR</p>
              <Button
                type="button"
                variant="secondary"
                className="text-primary"
                onClick={() => setModalOpen(true)}
              >
                Add Link
              </Button>
            </>
          )}
        </div>
      </label>
      <Modal
        open={modalOpen && user?.role === "admin"}
        setOpen={setModalOpen}
        center
        body={
          <div className="flex flex-col gap-2">
            <h3 className="text-lg font-semibold">Add Image Link</h3>
            <p>
              Navigate to{" "}
              <Link
                to="/dashboard/media"
                target="_blank"
                className="text-blue-500"
              >
                Image Gallery
              </Link>{" "}
              and copy the link of the image of your choice and paste it in the
              field below
            </p>
            <input
              className="w-full appearance-none rounded-md bg-[#f5f8fa] box-border border border-[#cbd6e2] py-2 px-3 text-gray-700 leading-tight focus:outline-none mt-2 hover:border-primary focus:border-primary transition-all duration-300"
              placeholder="Paste image link from gallery"
              type="search"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <div className="flex justify-end gap-2">
              <GGButton
                type="button"
                variant="outlined"
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </GGButton>
              <GGButton type="button" onClick={onAddLinkClick} disable={!value}>
                Add <RiArrowRightLine />
              </GGButton>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default ImageUpload;
