import React from "react";
import { Loader, StaticPageTemplate } from "components";
import { useGetStaticPagesQuery } from "store/api";

const TermsAndConditions = () => {
  const { data, isFetching } = useGetStaticPagesQuery("staticPages");

  console.log("data", data);

  const page = data?.pages.find(
    (item: any) => item.pageId === "TERMS_AND_CONDITIONS_PAGE",
  );

  return (
    <div>
      {isFetching ? <Loader showText /> : <StaticPageTemplate page={page} />}
    </div>
  );
};

export default TermsAndConditions;
