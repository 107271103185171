import { DeleteDialog } from "components/Common";
import { type BannerProps } from "interfaces";
import React from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDeleteBannerMutation } from "store/api/requests/banners/deleteBannerApiSlice";

const BannerCard = ({ banner }: { banner: BannerProps }) => {
  const navigate = useNavigate();
  const [onDelete, setOnDelete] = React.useState(false);
  const [deleteBanner, { isLoading: isDeleting }] = useDeleteBannerMutation();

  const onDeleteHandler = async () => {
    const searchParams = new URLSearchParams(location.search);
    const selectedBanner = searchParams.get("bannerId");
    try {
      await deleteBanner({ id: selectedBanner as string }).unwrap();
      navigate({
        search: "",
      });
      setOnDelete(false);
      toast.success("Screen deleted successfully");
    } catch (e) {
      toast.error("Failed to delete screen");
    }
  };

  const cancelHandler = () => {
    setOnDelete(false);
    navigate({
      search: "",
    });
  };

  return (
    <>
      <DeleteDialog
        show={onDelete}
        itemName="OnBoarding Screen"
        actionName="Delete"
        onCancel={cancelHandler}
        onConfirm={onDeleteHandler}
        isLoading={isDeleting}
      />
      <div className="group cursor-pointer h-40 w-full bg-linear flex flex-col justify-between items-center gap-2 p-3 rounded-md relative">
        <img
          src={banner.image}
          alt="banner"
          className="w-auto h-4/5 rounded-md"
        />
        <h4 className="text-white font-medium font-poppins text-center line-clamp-1">
          {banner.title}
        </h4>

        <div className="flex items-center justify-center gap-2 mb-3  opacity-0 group-hover:opacity-100 absolute top-1 right-2">
          <button
            onClick={() => {
              navigate(banner.docId);
            }}
            title="Edit"
            className="bg-white text-primary font-semibold text-sm p-2.5 rounded-full mt-3 shadow-md"
          >
            <AiOutlineEdit />
          </button>

          <button
            onClick={() => {
              setOnDelete(true);
              const queryParams = new URLSearchParams();
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
              queryParams.set("bannerId", banner.docId as string);
              navigate({
                search: `?${queryParams.toString()}`,
              });
            }}
            title="Delete"
            className="bg-white text-primary font-semibold text-sm p-2.5 rounded-full mt-3 shadow-md"
          >
            <BiTrash />
          </button>
        </div>
      </div>
    </>
  );
};

export default BannerCard;
