import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { GoHomeFill } from "react-icons/go";
import { BsCartFill } from "react-icons/bs";
import { FiTruck } from "react-icons/fi";
import {
  FaCartFlatbed,
  FaChartSimple,
  FaCircleQuestion,
  FaStore,
} from "react-icons/fa6";
import { FaStar, FaUsersCog, FaUser, FaFlag } from "react-icons/fa";
import { SiPowerpages } from "react-icons/si";
import LogoHorizontal from "../../assets/images/logoHorizontal.svg";
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { selectUser } from "store/slices";
import { UserRoles, type UserType } from "interfaces";
import { BiSolidCategoryAlt } from "react-icons/bi";

const Sidebar = ({
  showMenu,
  setShowMenu,
}: {
  showMenu: boolean;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const user: UserType = useSelector(selectUser);
  const [openAccordion, setOpenAccordion] = useState<boolean>(false);

  const NAV_ITEMS = [
    {
      itemName: "overview",
      icon: <GoHomeFill />,
      role: "all",
      link: "overview",
    },
    {
      itemName: "my store",
      icon: <FaStore />,
      role: UserRoles.Vendor,
      link: "my-store",
    },
    {
      itemName: "stores",
      icon: <FaStore />,
      role: UserRoles.Admin,
      link: "stores",
    },
    {
      itemName: "products",
      icon: <BsCartFill />,
      role: "all",
      link: "products",
    },
    {
      itemName: "categories",
      icon: <BsCartFill />,
      role: UserRoles.Admin,
      link: "product-categories",
    },
    {
      itemName: "sub categories",
      icon: <BiSolidCategoryAlt />,
      role: UserRoles.Admin,
      link: "sub-categories",
    },
    {
      itemName: "orders",
      icon: <FaCartFlatbed />,
      role: "all",
      link: "orders",
    },
    { itemName: "sales", icon: <FaChartSimple />, role: "none", link: "sales" },
    {
      itemName: "reviews",
      icon: <FaStar />,
      role: UserRoles.Admin,
      link: "reviews",
    },
    {
      itemName: "help",
      icon: <FaCircleQuestion />,
      role: UserRoles.Vendor,
      link: "help",
    },
    {
      itemName: "users",
      icon: <FaUsersCog />,
      role: UserRoles.Admin,
      link: "users",
    },
    { itemName: "profile", icon: <FaUser />, role: "all", link: "profile" },
    {
      itemName: "Image Gallery",
      icon: <FaUser />,
      role: UserRoles.Admin,
      link: "media",
    },
    {
      itemName: "app settings",
      icon: <SiPowerpages />,
      role: UserRoles.Admin,
      onClick: () => {
        setOpenAccordion((prev) => !prev);
      },
      subItem: [
        { itemName: "banners", icon: <FaFlag />, link: "banners" },
        { itemName: "static pages", icon: <FaFlag />, link: "static-pages" },
        {
          itemName: "company socials",
          icon: <FaFlag />,
          link: "company-socials",
        },
        {
          itemName: "onboarding screen ",
          icon: <FaFlag />,
          link: "onboarding-screen",
        },
        {
          itemName: "delivery costs",
          icon: <FiTruck />,
          link: "delivery-costs",
        },
        { itemName: "faqs", icon: <FaFlag />, link: "faqs" },
      ],
      link: "app-settings",
    },
  ];

  const renderedItems = () => {
    if (user?.role === UserRoles.Admin) {
      return NAV_ITEMS.filter(
        (item) => item.role !== UserRoles.Vendor && item.role !== "none",
      );
    }
    return NAV_ITEMS.filter(
      (item) => item.role !== UserRoles.Admin && item.role !== "none",
    );
  };

  const navigationItems = renderedItems();

  return (
    <div
      className={`h-screen no-scrollbar w-[250px] bg-linear z-50 fixed left-0 rounded-r-[30px] flex round overflow-auto transition-all duration-500 ease-in-out  ${showMenu ? "translate-x-0" : "max-md:translate-x-[-250px]"}`}
    >
      <div className="bg-primary w-[40px] rounded-r-[16px] h-screen flex flex-col items-center pt-[6rem] ">
        {navigationItems.map((item) => (
          <NavLink
            key={Math.random()}
            to={`/dashboard/${item.link}`}
            className={({ isActive }) =>
              "flex gap-x-2 items-center min-h-8 w-full my-[4px]  before:w-1 before:h-full  before:rounded-r-[30px] first-of-type:mt-0 " +
              (isActive ? "before:bg-white" : " ")
            }
            onClick={() => {
              if (item.onClick) {
                item.onClick();
              }
              setShowMenu(false);
            }}
          >
            <div className="text-white">{item.icon}</div>
          </NavLink>
        ))}
      </div>
      <div className="navigation">
        <div className="mt-8 mb-6 mx-4">
          <img src={LogoHorizontal} className="w-[180px]" />
        </div>
        <ul className="w-full list-none">
          {navigationItems.map((item: any) => (
            <li className="w-full" key={Math.random()}>
              <NavLink
                to={`/dashboard/${item.link}`}
                className={({ isActive }) =>
                  " w-full rounded-l-3xl px-6 py-2 my-1 font-poppins font-semibold flex justify-between items-center hover:bg-primary hover:text-white transition-all duration-300 text-sm no-underline capitalize  " +
                  (isActive ? "bg-white text-linear" : "text-white ")
                }
                onClick={() => {
                  if (item.onClick) {
                    item.onClick();
                  }
                  setShowMenu(false);
                }}
              >
                {item.itemName === "user" ? "dashboard" : item.itemName}
                {item.subItem &&
                  (!openAccordion ? (
                    <VscTriangleDown className="h-[16px] w-[16px]" />
                  ) : (
                    <VscTriangleUp className="h-[16px] w-[16px]" />
                  ))}
              </NavLink>
              {item.subItem && openAccordion && (
                <ul className="list-none w-full h-28 sm:h-full overflow-auto">
                  {item.subItem.map((subItem: any) => (
                    <li className="w-full" key={Math.random()}>
                      <NavLink
                        to={`/dashboard/app-settings/${subItem.link}`}
                        className={({ isActive }) =>
                          "block w-full rounded-l-3xl px-6 py-1 my-1 font-poppins font-medium hover:bg-primary hover:text-white transition-all duration-300 text-sm no-underline capitalize  " +
                          (isActive ? "bg-white text-linear " : "text-white ")
                        }
                        onClick={() => {
                          setShowMenu(false);
                        }}
                      >
                        {subItem.itemName === "user"
                          ? "dashboard"
                          : subItem.itemName}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
