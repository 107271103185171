export const ProductColors = [
  { label: "Red", value: "#FF0000", hex: "#FF0000" },
  { label: "Green", value: "#00FF00", hex: "#00FF00" },
  { label: "Blue", value: "#0000FF", hex: "#0000FF" },
  { label: "Yellow", value: "#FFFF00", hex: "#FFFF00" },
  { label: "Cyan", value: "#00FFFF", hex: "#00FFFF" },
  { label: "Magenta", value: "#FF00FF", hex: "#FF00FF" },
  { label: "Black", value: "#000000", hex: "#000000" },
  { label: "White", value: "#FFFFFF", hex: "#FFFFFF" },
  { label: "Gray", value: "#808080", hex: "#808080" },
  { label: "Dark Red", value: "#8B0000", hex: "#8B0000" },
  { label: "Dark Green", value: "#006400", hex: "#006400" },
  { label: "Dark Blue", value: "#00008B", hex: "#00008B" },
  { label: "Dark Yellow", value: "#CDCD00", hex: "#CDCD00" },
  { label: "Dark Cyan", value: "#008B8B", hex: "#008B8B" },
  { label: "Dark Magenta", value: "#8B008B", hex: "#8B008B" },
  { label: "Dark Gray", value: "#A9A9A9", hex: "#A9A9A9" },
  { label: "Light Gray", value: "#D3D3D3", hex: "#D3D3D3" },
  { label: "Maroon", value: "#800000", hex: "#800000" },
  { label: "Olive", value: "#808000", hex: "#808000" },
  { label: "Navy", value: "#000080", hex: "#000080" },
  { label: "Purple", value: "#800080", hex: "#800080" },
  { label: "Teal", value: "#008080", hex: "#008080" },
  { label: "Silver", value: "#C0C0C0", hex: "#C0C0C0" },
  { label: "Orange", value: "#FFA500", hex: "#FFA500" },
  { label: "Gold", value: "#FFD700", hex: "#FFD700" },
  { label: "Indigo", value: "#4B0082", hex: "#4B0082" },
  { label: "Violet", value: "#EE82EE", hex: "#EE82EE" },
  { label: "Pink", value: "#FFC0CB", hex: "#FFC0CB" },
  { label: "Brown", value: "#A52A2A", hex: "#A52A2A" },
  { label: "Cream", value: "#F8E2B1", hex: "#F8E2B1" },
];

export const productSizes = [
  { label: "XS", value: "XS" },
  { label: "S", value: "S" },
  { label: "M", value: "M" },
  { label: "L", value: "L" },
  { label: "XL", value: "XL" },
  { label: "XXL", value: "XXL" },
  { label: "XXXL", value: "XXXL" },
];
export const shoeSizes = [
  { value: "eu-35-36", label: "EU-35-36" },
  { value: "uk-3", label: "UK-3" },
  { value: "eu-36", label: "EU-36" },
  { value: "uk-3.5", label: "UK-3.5" },
  { value: "eu-36-37", label: "EU-36-37" },
  { value: "uk-4", label: "UK-4" },
  { value: "eu-37", label: "EU-37" },
  { value: "uk-4.5", label: "UK-4.5" },
  { value: "eu-37-38", label: "EU-37-38" },
  { value: "uk-5", label: "UK-5" },
  { value: "eu-38", label: "EU-38" },
  { value: "uk-5.5", label: "UK-5.5" },
  { value: "eu-38-39", label: "EU-38-39" },
  { value: "uk-6", label: "UK-6" },
  { value: "eu-39", label: "EU-39" },
  { value: "uk-6.5", label: "UK-6.5" },
  { value: "eu-39-40", label: "EU-39-40" },
  { value: "uk-7", label: "UK-7" },
  { value: "eu-40", label: "EU-40" },
  { value: "uk-7.5", label: "UK-7.5" },
  { value: "eu-40-41", label: "EU-40-41" },
  { value: "uk-8", label: "UK-8" },
  { value: "eu-41", label: "EU-41" },
  { value: "uk-8.5", label: "UK-8.5" },
  { value: "eu-41-42", label: "EU-41-42" },
  { value: "uk-9", label: "UK-9" },
  { value: "eu-42", label: "EU-42" },
  { value: "uk-9.5", label: "UK-9.5" },
  { value: "eu-42-43", label: "EU-42-43" },
  { value: "uk-10", label: "UK-10" },
];

export const socialMedia = [
  {
    name: "Call",
    icon: "https://cdn-icons-png.flaticon.com/512/597/597177.png",
    link: "tel:username",
  },
  {
    name: "Facebook",
    icon: "https://cdn-icons-png.flaticon.com/512/124/124010.png",
    link: "https://www.facebook.com/username", // Replace with actual username
  },
  {
    name: "Twitter",
    icon: "https://cdn-icons-png.flaticon.com/512/733/733579.png",
    link: "https://twitter.com/username", // Replace with actual username
  },
  {
    name: "Instagram",
    icon: "https://cdn-icons-png.flaticon.com/512/2111/2111463.png",
    link: "https://www.instagram.com/username", // Replace with actual username
  },
  {
    name: "LinkedIn",
    icon: "https://cdn-icons-png.flaticon.com/512/174/174857.png",
    link: "https://www.linkedin.com/in/username", // Replace with actual username
  },
  {
    name: "Snapchat",
    icon: "https://imagedelivery.net/hN43VFjQOkIgkZeSM1-gCQ/412d2cef-6405-4911-628a-7ffa74528800/public",
    link: "https://www.snapchat.com/add/username", // Replace with actual username
  },
  {
    name: "Pinterest",
    icon: "https://cdn-icons-png.flaticon.com/512/174/174863.png",
    link: "https://www.pinterest.com/username", // Replace with actual username
  },
  {
    name: "YouTube",
    icon: "https://cdn-icons-png.flaticon.com/512/1384/1384060.png",
    link: "https://www.youtube.com/user/username", // Replace with actual username
  },
  {
    name: "TikTok",
    icon: "https://cdn-icons-png.flaticon.com/512/3046/3046126.png",
    link: "https://www.tiktok.com/@username", // Replace with actual username
  },
  {
    name: "Reddit",
    icon: "https://cdn-icons-png.flaticon.com/512/174/174866.png",
    link: "https://www.reddit.com/user/username", // Replace with actual username
  },
  {
    name: "WhatsApp",
    icon: "https://cdn-icons-png.flaticon.com/512/733/733585.png",
    link: "https://wa.me/username", // Replace with actual phone number
  },
  {
    name: "Tumblr",
    icon: "https://cdn-icons-png.flaticon.com/512/733/733589.png",
    link: "https://username.tumblr.com", // Replace with actual username
  },
  {
    name: "Flickr",
    icon: "https://cdn-icons-png.flaticon.com/512/2111/2111363.png",
    link: "https://www.flickr.com/people/username", // Replace with actual username
  },
  {
    name: "Vimeo",
    icon: "https://cdn-icons-png.flaticon.com/512/174/174890.png",
    link: "https://vimeo.com/username", // Replace with actual username
  },
  {
    name: "Discord",
    icon: "https://cdn-icons-png.flaticon.com/512/2111/2111370.png",
    link: "https://discord.gg/username", // Replace with actual username or invite link
  },
  {
    name: "Telegram",
    icon: "https://cdn-icons-png.flaticon.com/512/2111/2111646.png",
    link: "https://t.me/username", // Replace with actual username
  },
  {
    name: "Quora",
    icon: "https://cdn-icons-png.flaticon.com/512/2111/2111661.png",
    link: "https://www.quora.com/profile/username", // Replace with actual username
  },
  {
    name: "Signal",
    icon: "https://cdn-icons-png.flaticon.com/512/2111/2111753.png",
    link: "https://signal.me/#p/+1234567890", // Replace with actual phone number
  },
  {
    name: "Medium",
    icon: "https://cdn-icons-png.flaticon.com/512/2111/2111508.png",
    link: "https://medium.com/@username", // Replace with actual username
  },
];
