import React, { useState } from "react";
import {
  BreadCrumb,
  Button,
  DangerCard,
  DeleteDialog,
  GGButton,
  GGChip,
  GGTable,
  LiveTimeDisplay,
  Loader,
  Modal,
  ORDER_COLUMNS,
  SectionTitle,
} from "components";
import { UserRoles, type FirebaseDate } from "interfaces";
import { IoShieldCheckmark } from "react-icons/io5";
import { TbInfoTriangle } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import { type ToastContent, toast } from "react-toastify";
import {
  useApproveShopMutation,
  useDeactivateShopMutation,
  useDeleteShopMutation,
  useGetShopByIdQuery,
  useGetStoreDetailsQuery,
  useGetStoreOrdersQuery,
} from "store/api";
import { formattedDate } from "utils";
import { useSelector } from "react-redux";
import { selectUser } from "store/slices";

const SingleStore = () => {
  const user = useSelector(selectUser);
  const [isDeactivate, setIsDeactivate] = useState(false);
  const [deactivatedReason, setDeactivateReason] = useState("");
  const navigate = useNavigate();
  const { storeId } = useParams();
  const { data, isFetching, refetch } = useGetShopByIdQuery({ id: storeId });
  const { data: storeDetails } = useGetStoreDetailsQuery({
    id: storeId,
  });

  const [approveShop, { isLoading }] = useApproveShopMutation();
  const [deleteStore, { isLoading: isDeleting }] = useDeleteShopMutation();
  const [deactivateShop, { isLoading: isDeactivating }] =
    useDeactivateShopMutation();
  const [modalOpen, setModalOpen] = useState(false);

  const storeData = data?.store || {};
  const storeInfo = storeDetails?.storeOwner || {};

  const approveShopHandler = async () => {
    try {
      await approveShop({ id: storeId })
        .unwrap()
        .then(async () => {
          await refetch();
        });
      // TODO: add refresh functionality to get the updated shop details after approval
      toast.success(`${storeData?.name} approved successfully`);
    } catch (error: any) {
      toast.error(error.message as ToastContent<unknown>);
    }
  };
  const rejectShopHandler = async () => {};

  const deactivateShopHandler = async () => {
    try {
      await deactivateShop({ id: storeId, deactivatedReason })
        .unwrap()
        .then(async () => {
          await refetch();
        });

      setIsDeactivate(false);
      toast.success("Shop deactivated successfully");
    } catch (e) {
      toast.error("Failed to deactivate shop");
    }
  };

  const { data: allOrders, isFetching: isFetchingOrders } =
    useGetStoreOrdersQuery({
      id: storeId,
    });
  const ordersData = allOrders?.orders ?? [];

  const formattedOrders = ordersData.map((order: any) => {
    return {
      ...order,
      amount:
        user?.role === UserRoles.Admin ? order.totalPrice : order.productPrice,
      quantity: order.quantity,
      // user?.role === UserRoles.Admin
      //   ? order?.orderDetails?.reduce(
      //       (acc: number, item: any) => acc + item.quantity,
      //       0,
      //     )
      //   : order.quantity,
    };
  });

  const isVerified = storeData?.isVerified as boolean;

  const ownerData = {
    name: storeInfo?.firstName + " " + storeInfo?.lastName,
    phone: storeInfo?.phone,
    email: storeInfo?.email,
  };

  const isDeactivated = !!storeData?.deactivatedAt;

  const onDeleteHandler = async () => {
    try {
      setModalOpen(false);
      await deleteStore({ id: storeId }).unwrap();
      toast.success("Store Deleted Successfully");
      navigate("../products");
    } catch (error) {
      toast.error("Failed to delete store, Please try again later.");
    }
  };
  return (
    <>
      <BreadCrumb lastName={storeData?.name} />
      {isFetching ? (
        <Loader showText />
      ) : (
        <>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 ">
            <div className="card sm:col-span-2">
              <SectionTitle title="Store Details" />

              <div className="flex flex-col items-start sm:flex-row gap-3">
                <div className="w-full md:w-2/6">
                  <img
                    src={storeData?.image}
                    alt={storeData?.name}
                    className="w-full h-auto max-sm:w-full sm:w-full sm:h-auto rounded-md"
                  />
                </div>
                <div className="flex-1">
                  <h6 className="flex items-center gap-1 font-poppins font-medium text-xl capitalize sm:font-semibold text-textColor">
                    {storeData?.name}
                    {isVerified ? (
                      <IoShieldCheckmark className="text-[#3c933c] w-[16px] h-[16px]" />
                    ) : (
                      <span className="text-xs p-1 px-1.5 font-normal text-white rounded-full bg-slate-300">
                        Unverified
                      </span>
                    )}
                  </h6>
                  <p className="text-sm font-poppins text-gray-500">
                    {storeData?.description}
                  </p>
                  <div className="flex items-center gap-2 font-medium">
                    <span className="text-sm font-poppins text-gray-500 capitalize">
                      {`${storeData?.city} - ${storeData?.location}`}
                    </span>
                  </div>
                  <div className="my-3">
                    <div className="flex items-center gap-2">
                      <span className="text-sm font-poppins text-gray-500">
                        Owner: {ownerData.name}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-sm font-poppins text-gray-500">
                        Phone: {ownerData.phone}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-sm font-poppins text-gray-500">
                        Email: {ownerData.email}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-1 mt-2">
                    {storeData?.categories?.map((category: any) => (
                      <GGChip
                        key={category.value}
                        bgColor="bg-linear"
                        textColor="text-white"
                        variant="filled"
                      >
                        {category.name}
                      </GGChip>
                    ))}
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <SectionTitle title="Store Gallery" />
                <div className="flex justify-start items-center gap-4 flex-wrap">
                  {storeData?.gallery?.map((galleryImage: string) => (
                    <img
                      key={galleryImage}
                      src={galleryImage}
                      alt={storeData?.name}
                      className="w-[150px] h-[150px] rounded-md border border-borderColor border-solid"
                    />
                  ))}
                </div>
              </div>
            </div>
            <div>
              <div className="card mb-3 select-none">
                <div className="flex justify-between">
                  <div>
                    <span
                      className={`flex items-center gap-1 text-sm font-poppins ${isVerified ? "bg-liveColor" : "bg-borderColor"} rounded-full text-white font-medium px-2 py-0.5`}
                    >
                      {isVerified ? (
                        <>
                          <div className="h-2 w-2 rounded-full bg-white" />
                          Live
                        </>
                      ) : (
                        "Not Live"
                      )}
                    </span>
                  </div>
                  <div
                    className={`live-loader ${isVerified ? "" : "inactive"}`}
                  ></div>
                </div>
                {isVerified && (
                  <div className="py-3">
                    <p className="font-poppins">
                      Live on Glam n Go app since{" "}
                      <LiveTimeDisplay
                        approvedAt={storeData?.approvedAt as FirebaseDate}
                      />{" "}
                      ago
                    </p>
                  </div>
                )}
                {isDeactivated && !isVerified && (
                  <div className="py-3">
                    <p className="font-poppins">
                      Deactivated for now{" "}
                      <LiveTimeDisplay
                        approvedAt={storeData?.deactivatedAt as FirebaseDate}
                      />{" "}
                      for reason stated as{" "}
                      <span className="italic text-linear">
                        {storeData?.deactivatedReason}
                      </span>
                    </p>
                  </div>
                )}
                <div className="italic bg-[aliceblue] p-2 rounded-md text-sm mt-2">
                  Created on{" "}
                  <span className="font-medium">
                    {formattedDate(storeData?.createdAt as FirebaseDate)}
                  </span>
                </div>
              </div>
              <div className="card">
                <SectionTitle title="Actions" />

                {storeData?.isVerified === true ? (
                  <>
                    <div className="mb-4">
                      <div className=" bg-primaryLight/5 font-poppins text-sm p-4 rounded-sm">
                        <span className="flex justify-center text-center ">
                          <TbInfoTriangle className="w-[32px] h-[32px]" />
                        </span>
                        <p>
                          Deactivated stores will not be visible to the mobile
                          app users, Please be sure before deactivating{" "}
                          <span className="font-semibold">
                            {storeData?.name}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center justify-between gap-3">
                      <DeleteDialog
                        show={isDeactivate}
                        onConfirm={deactivateShopHandler}
                        onCancel={() => {
                          setIsDeactivate(false);
                        }}
                        itemName="store"
                        actionName="deactivate"
                        showMessage
                        messagePlaceholder="Enter reason for deactivation"
                        messageHandler={(
                          e: React.ChangeEvent<HTMLTextAreaElement>,
                        ) => {
                          setDeactivateReason(e.target.value);
                        }}
                      />
                      <button
                        onClick={() => {
                          setIsDeactivate(true);
                        }}
                        type="button"
                        className="w-full flex justify-center items-center px-4 py-2 border border-transparent text-sm font-semibold rounded-full shadow-sm bg-primaryLight text-primary hover:bg-primary/20 focus:outline-none"
                      >
                        Deactivate Store
                      </button>
                      <button
                        onClick={() => {
                          navigate(`../stores/${storeId}/edit`);
                        }}
                        type="button"
                        className="w-full flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm bg-primary hover:bg-primary/80 text-white"
                      >
                        Edit Store
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mb-4">
                      <div className=" bg-primaryLight/5 font-poppins text-sm p-4 rounded-sm ">
                        <span className="flex justify-center text-center ">
                          <TbInfoTriangle className="w-[32px] h-[32px]" />
                        </span>
                        Approved stores will be visible to the mobile app users,
                        Please go through shop details before approving{" "}
                        <span className="font-semibold">{storeData?.name}</span>
                      </div>
                    </div>
                    <div className="flex items-center w-full justify-between gap-3">
                      <button
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={rejectShopHandler}
                        disabled={isLoading || isDeactivating}
                        type="button"
                        className="flex w-full items-center justify-center px-4 py-2 border border-transparent text-sm font-semibold rounded-full shadow-sm bg-primaryLight text-primary hover:bg-primary/20 focus:outline-none"
                      >
                        Reject Store
                      </button>
                      <button
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={approveShopHandler}
                        disabled={isLoading || isDeactivating}
                        type="button"
                        className="flex w-full items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm bg-primary hover:bg-primary/80 text-white disabled:cursor-not-allowed disabled:opacity-50"
                      >
                        {isLoading ? "Approving..." : " Approve Store"}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="w-full px-0 py-2 mt-6 card mb-6">
            <GGTable
              data={formattedOrders as any[]}
              columns={ORDER_COLUMNS}
              isLoading={isFetchingOrders}
              searchInputPlaceholder="Search orders..."
              emptyText={`No orders found for ${storeData?.name}`}
              tableHeader={`Orders for ${storeData?.name}`}
              onViewHandler={(order) => {
                navigate(`/dashboard/orders/${order?.docId}`);
              }}
              onEditHandler={(order) => {
                navigate(`/dashboard/orders/${order?.docId}?edit=true`);
              }}
            />
          </div>
          {isFetching ? null : (
            <>
              <Modal
                open={modalOpen}
                setOpen={setModalOpen}
                center
                body={
                  <div className="flex flex-col gap-2">
                    <h3 className="text-lg font-semibold">Delete Store</h3>
                    <p className="text-sm">
                      Are you sure you want to delete this store?, This action
                      is permanent and cannot be undone.
                    </p>
                    <div className="flex justify-end gap-2">
                      <GGButton
                        type="button"
                        variant="outlined"
                        onClick={() => setModalOpen(false)}
                      >
                        Cancel
                      </GGButton>
                      <GGButton
                        type="button"
                        onClick={onDeleteHandler}
                        loading={isDeleting}
                      >
                        Yes, Delete
                      </GGButton>
                    </div>
                  </div>
                }
              />
              <DangerCard
                className="mt-8"
                label="Danger zone"
                action={
                  <Button
                    onClick={() => setModalOpen(true)}
                    variant="secondary"
                    className="text-red-700"
                    loading={isDeleting}
                    disabled={isDeleting}
                  >
                    Delete Store
                  </Button>
                }
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default SingleStore;
