import { GLAM_N_GO_API } from "../../apiSlice";

export const editBannerApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    editBanner: builder.mutation({
      query: (data) => {
        const { id, ...rest } = data;
        return {
          url: `/banners/update/${id}`,
          method: "PATCH",
          body: rest,
        };
      },
      invalidatesTags: ["BANNERS"],
    }),
  }),
});

export const { useEditBannerMutation } = editBannerApiSlice;
