import { ImageUpload, Input } from "components/Common";
import { type FormComponentProps } from "interfaces";
import Select from "react-select";
import React, { type FC } from "react";
import { useGetAllCategoriesQuery } from "store/api";
import { MdLocationPin } from "react-icons/md";

const StoreForm: FC<FormComponentProps> = ({ values, setFieldValue }) => {
  const { data: categories } = useGetAllCategoriesQuery("categories");
  const categoriesToShow = categories?.categories.map(
    (category: { docId: any; name: any }) => {
      return { value: category?.docId, label: category.name };
    },
  );

  const gallery = [
    {
      name: "gallery1",
      value: values.gallery1,
    },
    {
      name: "gallery2",
      value: values.gallery2,
    },
    {
      name: "gallery3",
      value: values.gallery3,
    },
  ];

  return (
    <div className="flex justify-between gap-2 flex-col md:flex-row ">
      <div className="w-full md:w-1/2">
        <ImageUpload
          fieldName="image"
          setFieldValue={setFieldValue}
          label="Store Image"
          note="required"
          imageUrl={values.image}
          linkName="imageLink"
        />
        <div className="mt-2">
          <span className="block field-label mb-2.5">
            Store Gallery (Showcase what you deal in)
          </span>
          <div className="flex flex-col md:flex-row gap-2 flex-wrap">
            {gallery.map((item) => (
              <div className="mb-2.5" key={item.name}>
                <ImageUpload
                  fieldName={item.name}
                  setFieldValue={setFieldValue}
                  imageUrl={item.value}
                  linkName={`${item.name}Link`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2">
        <Input label="Store Name" name="name" type="text" note="required" />
        <Input
          label="Description"
          name="description"
          type="textarea"
          note="required"
          rows={5}
        />
        <Input
          label="Location"
          name="location"
          type="text"
          note="required"
          prepend={<MdLocationPin fontSize="large" />}
        />
        <Input label="City" name="city" type="text" note="required" />
        <div>
          <span className="block field-label mb-2.5">Categories</span>
          <Select
            isMulti
            isSearchable
            name="categories"
            onChange={(selectedOption) => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              setFieldValue("categories", selectedOption);
            }}
            options={categoriesToShow}
            value={values.categories}
            className="basic-multi-select"
            placeholder="Select Categories"
            classNamePrefix="select"
            theme={(theme) => ({
              ...theme,
              borderRadius: 8,
              colors: {
                ...theme.colors,
                primary25: "#DD4470",
                primary: "#971B3F",
                primary50: "#DD4470",
              },
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default StoreForm;
