import React, { useEffect, useState } from "react";
import {
  SectionTitle,
  GGButton,
  Spinner,
  BreadCrumb,
  Loader,
} from "components";
import { Field, Formik } from "formik";
import { type ToastContent, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeactivateShopMutation,
  useEditShopMutation,
  useLazyGetShopByIdQuery,
  useNewShopMutation,
  useUploadImageMutation,
} from "store/api";
import { getCloudFlareImageUrl } from "utils";
import StoreForm from "./StoreForm";
import { StoreSchema, editStoreSchema } from "../schemas";
import { TbInfoTriangle } from "react-icons/tb";

const AddShop = ({ isEdit }: { isEdit?: boolean }) => {
  const navigate = useNavigate();
  const { storeId } = useParams();
  const [loading, setLoading] = useState(false);
  const [newShop, { isLoading }] = useNewShopMutation();
  const [editShop, { isLoading: isEditing }] = useEditShopMutation();
  const [getStore, { isFetching }] = useLazyGetShopByIdQuery();
  const [store, setStore] = useState<any>({});
  const [uploadImage, { isLoading: uploadImageIsLoading }] =
    useUploadImageMutation();
  const [deactivateShop] = useDeactivateShopMutation();

  const newStoreSubmitHandler = async (values: any) => {
    if (
      !values.gallery1 &&
      !values.gallery2 &&
      !values.gallery3 &&
      !values.gallery1Link &&
      !values.gallery2Link &&
      !values.gallery3Link
    ) {
      return toast.error(
        "Please upload at least one image in the gallery section",
      );
    }
    try {
      setLoading(true);
      if (isEdit) {
        if (values.image instanceof Blob && !values.imageLink) {
          const imageUploadRes = await uploadImage(values.image).unwrap();
          values.image = getCloudFlareImageUrl(imageUploadRes?.result);
        }
        values.gallery1 = values.gallery1Link || values.gallery1;
        values.gallery2 = values.gallery2Link || values.gallery2;
        values.gallery3 = values.gallery3Link || values.gallery3;

        const gallery = [values.gallery1, values.gallery2, values.gallery3];
        if (gallery.some((g) => g instanceof Blob)) {
          const galleryImage = await Promise.all(
            gallery.map(async (img) => {
              if (!(img instanceof Blob)) return img;
              const galleryImageRes = await uploadImage(img).unwrap();
              return getCloudFlareImageUrl(galleryImageRes?.result);
            }),
          );

          values.gallery1 = galleryImage[0];
          values.gallery2 = galleryImage[1];
          values.gallery3 = galleryImage[2];
        }

        await editShop({
          id: storeId,
          name: values.name,
          description: values.description,
          image: values.imageLink || values.image,
          categories: values.categories.map((category: any) => ({
            name: category.label,
            id: category.value,
          })),
          location: values.location,
          city: values.city,
          gallery: [
            values.gallery1Link || values.gallery1,
            values.gallery2Link || values.gallery2,
            values.gallery3Link || values.gallery3,
          ].filter((g) => g != null && g.trim().length > 0 && g !== ""),
        }).unwrap();
        await deactivateShop({
          id: storeId,
          deactivatedReason:
            "Changes made to the shop details needs to be reviewed by the admin",
        }).unwrap();
        toast.success("Store updated successfully");
      } else {
        let image = "";
        if (!values.imageLink) {
          const imageUploadRes = await uploadImage(values.image).unwrap();
          image = getCloudFlareImageUrl(imageUploadRes?.result);
        }
        values.gallery1 = values.gallery1Link || values.gallery1;
        values.gallery2 = values.gallery2Link || values.gallery2;
        values.gallery3 = values.gallery3Link || values.gallery3;

        const shopGallery = [values.gallery1, values.gallery2, values.gallery3];
        if (
          shopGallery.length > 0 &&
          shopGallery.some((g) => g instanceof Blob)
        ) {
          const subCategoryImages = await Promise.all(
            shopGallery.map(async (img: any) => {
              if (!(img instanceof Blob)) return img;
              const imageUploadRes = await uploadImage(img).unwrap();
              return getCloudFlareImageUrl(imageUploadRes?.result);
            }),
          );
          values.gallery1 = subCategoryImages[0];
          values.gallery2 = subCategoryImages[1];
          values.gallery3 = subCategoryImages[2];
        }
        await newShop({
          name: values.name,
          description: values.description,
          image: values.imageLink || image,
          categories: values.categories.map((category: any) => ({
            name: category.label,
            id: category.value,
          })),
          location: values.location,
          city: values.city,
          gallery: [
            values.gallery1Link || values.gallery1,
            values.gallery2Link || values.gallery2,
            values.gallery3Link || values.gallery3,
          ].filter((g) => g != null && g.trim().length > 0 && g !== ""),
        }).unwrap();
        toast.success("Store added successfully");
      }
      setLoading(false);
      navigate("../stores");
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message as ToastContent<unknown>);
    }
  };

  const fetchData = async () => {
    const fetchedData = await getStore({ id: storeId }).unwrap();
    setStore(fetchedData.store);
  };

  useEffect(() => {
    if (isEdit && storeId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchData();
    }
  }, [isEdit, storeId]);

  return (
    <div>
      <BreadCrumb />
      <div className="card">
        {isFetching && isEdit ? (
          <Loader />
        ) : (
          <>
            {isEdit && (
              <div className="mb-4">
                <div className=" bg-primaryLight/5 font-poppins text-sm p-4 rounded-sm flex items-center gap-3">
                  <TbInfoTriangle className="w-[32px] h-[32px]" />
                  Editing this store&apos;s details as Vendor will make it
                  temporarily deactivated until the changes are reviewed and
                  approved by the admin, You can contact the admin to update the
                  information for you if you don&apos;t want the store to be
                  deactivated.
                </div>
              </div>
            )}
            <SectionTitle title="Store Details" />
            <Formik
              enableReinitialize
              initialValues={{
                name: store?.name || "",
                description: store?.description || "",
                image: store?.image || null,
                categories:
                  store?.categories?.map((cat: any) => ({
                    label: cat.name,
                    value: cat.id,
                  })) || [],
                city: store?.city || "",
                location: store?.location || "",
                gallery1: store?.gallery ? store?.gallery[0] : "",
                gallery2: store?.gallery ? store?.gallery[1] : "",
                gallery3: store?.gallery ? store?.gallery[2] : "",
                gallery: store?.gallery || [],
                approved: false,
                imageLink: null,
                gallery1Link: null,
                gallery2Link: null,
                gallery3Link: null,
              }}
              validationSchema={isEdit ? editStoreSchema : StoreSchema}
              onSubmit={async (values) => {
                await newStoreSubmitHandler(values);
              }}
            >
              {({ handleSubmit, values, setFieldValue, dirty, isValid }) => (
                <form data-gtm-form-interact-id="043" onSubmit={handleSubmit}>
                  <StoreForm values={values} setFieldValue={setFieldValue} />
                  {isEdit && (
                    <div className="flex field-label items-center mt-2">
                      <Field
                        id="terms-checkbox"
                        name="approved"
                        type="checkbox"
                        className="w-4 h-4 text-linear bg-gray-100 border-gray-300 rounded "
                      />
                      <label
                        htmlFor="terms-checkbox-label"
                        className="ml-2 font-medium -mt-2"
                      >
                        By proceeding to save changes, you agree to the fact
                        that your store will be temporarily deactivated until
                        the changes are reviewed and approved by the admin.
                      </label>
                    </div>
                  )}
                  <div className="flex justify-center gap-2 my-3 pt-3">
                    <GGButton
                      type="submit"
                      disable={
                        isLoading ||
                        loading ||
                        uploadImageIsLoading ||
                        !dirty ||
                        !isValid ||
                        isEditing ||
                        (isEdit && !values.approved)
                      }
                    >
                      {isLoading ||
                      uploadImageIsLoading ||
                      loading ||
                      isEditing ? (
                        <Spinner />
                      ) : isEdit ? (
                        "Save Changes"
                      ) : (
                        "Add Store"
                      )}
                    </GGButton>
                  </div>
                </form>
              )}
            </Formik>
          </>
        )}
      </div>
    </div>
  );
};

export default AddShop;
