import React, { useState } from "react";
import {
  BreadCrumb,
  Button,
  DangerCard,
  GGButton,
  GGChip,
  LiveTimeDisplay,
  Loader,
  Modal,
  SectionTitle,
} from "components";
import { type FirebaseDate } from "interfaces";
import { IoShieldCheckmark } from "react-icons/io5";
import { TbInfoTriangle } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDeleteShopMutation, useGetMyStoreQuery } from "store/api";
import { formattedDate } from "utils";
import { useSelector } from "react-redux";
import { MdOutlineRefresh } from "react-icons/md";

const MyStore = () => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.auth.user);
  const { data, isFetching, refetch } = useGetMyStoreQuery("myStore");
  const [deleteStore, { isLoading: isDeleting }] = useDeleteShopMutation();
  const [modalOpen, setModalOpen] = useState(false);

  const storeData = data?.stores?.[0] || {};

  const hasStore = data?.stores.length > 0;

  const isVerified = storeData?.isVerified as boolean;

  const isDeactivated = !!storeData?.deactivatedAt;

  const onDeleteHandler = async () => {
    try {
      setModalOpen(false);
      await deleteStore({ id: "455455" }).unwrap();
      toast.success("Store Deleted Successfully");
      navigate("../products");
    } catch (error) {
      toast.error("Failed to delete store, Please try again later.");
    }
  };

  return (
    <>
      <BreadCrumb lastName={storeData?.name} />
      {isFetching ? (
        <Loader showText />
      ) : (
        <>
          {hasStore ? (
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 ">
              <div className="card sm:col-span-2">
                <div className="flex justify-between items-center">
                  <SectionTitle title="Store Details" />
                  <Button
                    type="button"
                    title="Refresh"
                    variant="default"
                    onClick={refetch}
                  >
                    <MdOutlineRefresh className="text-lg" />{" "}
                  </Button>
                </div>
                <div className="flex flex-col items-start sm:flex-row gap-3">
                  <div className="w-full md:w-2/6">
                    <img
                      src={
                        storeData?.image ?? "https://via.placeholder.com/150"
                      }
                      alt={storeData?.name}
                      className="w-full h-auto max-sm:w-full sm:w-full sm:h-auto rounded-md"
                    />
                  </div>
                  <div className="flex-1">
                    <h6 className="flex items-center gap-1 font-poppins font-medium text-xl capitalize sm:font-semibold text-textColor">
                      {storeData?.name}
                      {isVerified ? (
                        <IoShieldCheckmark className="text-[#3c933c] w-[16px] h-[16px]" />
                      ) : (
                        <span className="text-xs p-1 px-1.5 font-normal text-white rounded-full bg-slate-300">
                          Unverified
                        </span>
                      )}
                    </h6>
                    <p className="text-sm font-poppins text-gray-500">
                      {storeData?.description}
                    </p>
                    <div className="flex items-center gap-2 font-medium">
                      <span className="text-sm font-poppins text-gray-500 capitalize">
                        {`${storeData?.city ?? ""} ${storeData?.city || storeData?.location ? "-" : ""} ${storeData?.location ?? ""}`}
                      </span>
                    </div>
                    <div className="my-3">
                      <div className="flex items-center gap-2">
                        <span className="text-sm font-poppins text-gray-500">
                          Owner: {user?.firstName} {user?.lastName}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-sm font-poppins text-gray-500">
                          Phone: {user?.phone}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-sm font-poppins text-gray-500">
                          Email: {user?.email}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-1 mt-2">
                      {storeData?.categories?.map((category: any) => (
                        <GGChip
                          key={category.name}
                          bgColor="bg-linear"
                          textColor="text-white"
                          variant="filled"
                        >
                          {category.name}
                        </GGChip>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <SectionTitle title="Store Gallery" />
                  <div className="flex justify-start items-center gap-4 flex-wrap">
                    {storeData?.gallery?.map((galleryImage: string) => (
                      <img
                        key={galleryImage}
                        src={galleryImage}
                        alt={storeData?.name}
                        className="w-[150px] h-[150px] rounded-md border border-borderColor border-solid"
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <div className="card mb-3 select-none">
                  <div className="flex justify-between">
                    <div>
                      <span
                        className={`flex items-center gap-1 text-sm font-poppins ${isVerified ? "bg-liveColor" : "bg-borderColor"} rounded-full text-white font-medium px-2 py-0.5`}
                      >
                        {isVerified ? (
                          <>
                            <div className="h-2 w-2 rounded-full bg-white" />
                            Live
                          </>
                        ) : (
                          "Not Live"
                        )}
                      </span>
                    </div>
                    <div
                      className={`live-loader ${isVerified ? "" : "inactive"}`}
                    ></div>
                  </div>
                  {isVerified && (
                    <div className="py-3">
                      <p className="font-poppins">
                        Live on Glam n Go app since{" "}
                        <LiveTimeDisplay
                          approvedAt={storeData?.approvedAt as FirebaseDate}
                        />{" "}
                        ago
                      </p>
                    </div>
                  )}
                  {isDeactivated && !isVerified && (
                    <div className="py-3">
                      <p className="font-poppins">
                        Deactivated for now{" "}
                        <LiveTimeDisplay
                          approvedAt={storeData?.deactivatedAt as FirebaseDate}
                        />{" "}
                        for reason stated as{" "}
                        <span className="italic text-linear">
                          {storeData?.deactivatedReason}
                        </span>
                      </p>
                    </div>
                  )}
                  <div className="italic bg-[aliceblue] p-2 rounded-md text-sm mt-2">
                    Created on{" "}
                    <span className="font-medium">
                      {formattedDate(storeData?.createdAt as FirebaseDate)}
                    </span>
                  </div>
                </div>
                <div className="card">
                  <SectionTitle title="Actions" />
                  {storeData?.isVerified && (
                    <p className="text-sm mb-2">
                      Editing the details of your store will temporarily make it
                      deactivated until the changes are reviewed by the admin
                    </p>
                  )}
                  {storeData?.isVerified ? (
                    <>
                      <div className="mb-4">
                        <div className=" bg-primaryLight/5 font-poppins text-sm p-4 rounded-sm">
                          <span className="flex justify-center text-center ">
                            <TbInfoTriangle className="w-[32px] h-[32px]" />
                          </span>
                          Deactivated stores will not be visible to the mobile
                          app users
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mb-4">
                        <div className=" bg-primaryLight/5 font-poppins text-sm p-4 rounded-sm ">
                          <span className="flex justify-center text-center ">
                            <TbInfoTriangle className="w-[32px] h-[32px]" />
                          </span>
                          <span className="font-semibold">
                            {storeData?.name}
                          </span>{" "}
                          will be visible to the mobile app users after being
                          activated by the admin, you can edit the details of
                          your store at any time but the changes will be
                          reviewed by the admin before being visible to the
                          users.
                        </div>
                      </div>
                    </>
                  )}
                  <button
                    onClick={() => {
                      navigate(`../my-store/${storeData?.docId}/edit`);
                    }}
                    type="button"
                    className="w-full flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm bg-primary hover:bg-primary/80 text-white"
                  >
                    Edit Store
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className=" card flex items-center flex-col justify-center">
              <p className="text-lg font-normal text-center">
                You do not have a store yet, Create a store to start selling on
                Glam n Go
              </p>
              <GGButton
                type="button"
                onClick={() => {
                  navigate("../stores/new");
                }}
              >
                Add Store
              </GGButton>
            </div>
          )}

          {!isFetching && hasStore && (
            <>
              <Modal
                open={modalOpen}
                setOpen={setModalOpen}
                center
                body={
                  <div className="flex flex-col gap-2">
                    <h3 className="text-lg font-semibold">Delete Store</h3>
                    <p className="text-sm">
                      Are you sure you want to delete this store?, This action
                      is permanent and cannot be undone.
                    </p>
                    <div className="flex justify-end gap-2">
                      <GGButton
                        type="button"
                        variant="outlined"
                        onClick={() => setModalOpen(false)}
                      >
                        Cancel
                      </GGButton>
                      <GGButton
                        type="button"
                        onClick={onDeleteHandler}
                        loading={isDeleting}
                      >
                        Yes, Delete
                      </GGButton>
                    </div>
                  </div>
                }
              />

              <DangerCard
                className={`mt-8 ${hasStore ? "" : "hidden"}`}
                label="Danger zone"
                action={
                  <Button
                    onClick={() => setModalOpen(true)}
                    variant="secondary"
                    className="text-red-700"
                    loading={isDeleting}
                    disabled={isDeleting}
                  >
                    Delete Store
                  </Button>
                }
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default MyStore;
