import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  SectionTitle,
  GGButton,
  Spinner,
  BreadCrumb,
  Loader,
  Button,
  DangerCard,
  Modal,
} from "components";
import { type ToastContent, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteProductMutation,
  useEditProductMutation,
  useLazyGetProductByIdQuery,
  useNewProductMutation,
  useUploadImageMutation,
} from "store/api";
import { generateAlphanumericId, getCloudFlareImageUrl } from "utils";
import ProductForm from "./ProductForm";
import { ProductSchema } from "../schemas";
import { ProductColors } from "data/data";

const AddProduct = ({ isEdit }: { isEdit?: boolean }) => {
  const { productId } = useParams();
  const navigate = useNavigate();

  const [uploadImage, { isLoading: uploadImageIsLoading }] =
    useUploadImageMutation();
  const [newProduct, { isLoading }] = useNewProductMutation();
  const [editProduct, { isLoading: isEditing }] = useEditProductMutation();
  const [getProduct, { isFetching }] = useLazyGetProductByIdQuery();
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteProduct, { isLoading: isDeleting }] = useDeleteProductMutation();
  const [product, setProduct] = useState<any>({});

  const productSubmitHandler = async (values: any) => {
    if (
      !values.gallery1 &&
      !values.gallery2 &&
      !values.gallery3 &&
      !values.gallery1Link &&
      !values.gallery2Link &&
      !values.gallery3Link
    ) {
      return toast.error(
        "Please upload at least one image in the gallery section",
      );
    }
    try {
      if (isEdit) {
        if (values.mainImage instanceof Blob && !values.mainImageLink) {
          const imageUploadRes = await uploadImage(values.mainImage).unwrap();
          values.mainImage = getCloudFlareImageUrl(imageUploadRes?.result);
        }

        const gallery = [
          values.gallery1Link || values.gallery1,
          values.gallery2Link || values.gallery2,
          values.gallery3Link || values.gallery3,
        ];

        if (gallery.some((g) => g instanceof Blob)) {
          const galleryImage = await Promise.all(
            gallery.map(async (img) => {
              if (!(img instanceof Blob)) return img;
              const galleryImageRes = await uploadImage(img).unwrap();
              return getCloudFlareImageUrl(galleryImageRes?.result);
            }),
          );

          values.gallery1 = galleryImage[0];
          values.gallery2 = galleryImage[1];
          values.gallery3 = galleryImage[2];
        }

        const colorsToSend =
          values.colors?.map((option: any) => ({
            hex: option.value,
          })) || [];

        await editProduct({
          id: productId,
          name: values.name,
          mainImage: values.mainImageLink || values.mainImage,
          brandName: values.brandName,
          description: values.description,
          category: values.category,
          regularPrice: values.regularPrice,
          price: values.price,
          subCategory: values.subCategory,
          stockStatus: values.stockStatus,
          quantityInStock:
            typeof values.quantityInStock === "number"
              ? values.quantityInStock
              : 0,
          unit: values.unit,
          sizes: values.sizes || [],
          colors: colorsToSend,
          storeId: values.storeId,
          tag: values.tag,
          gallery: [
            values.gallery1Link || values.gallery1,
            values.gallery2Link || values.gallery2,
            values.gallery3Link || values.gallery3,
          ].filter((g) => g != null && g.trim().length > 0 && g !== ""),
          keyFeatures: values.keyFeatures
            .split("\n")
            .filter((el: string) => el.length > 0)
            .map((el: string) => el.trim()),
          mainMaterial: values.mainMaterial,
          productWeight: values.productWeight,
          isDraft: values.isDraft,
          isPopular: values.isPopular,
          sku: product.sku,
        }).unwrap();
        toast.success("Product updated successfully");
      } else {
        let image = "";
        if (!values.mainImageLink) {
          const res = await uploadImage(values.mainImage).unwrap();
          image = getCloudFlareImageUrl(res?.result);
        }
        const productGallery = [
          values.gallery1Link || values.gallery1,
          values.gallery2Link || values.gallery2,
          values.gallery3Link || values.gallery3,
        ];

        if (
          productGallery.length > 0 &&
          productGallery.some((g) => g instanceof Blob)
        ) {
          const productGalleryImages = await Promise.all(
            productGallery.map(async (img) => {
              if (!(img instanceof Blob)) return img;
              const imageUploadRes1 = await uploadImage(img).unwrap();
              return getCloudFlareImageUrl(imageUploadRes1?.result);
            }),
          );
          values.gallery1 = values.gallery1Link || productGalleryImages[0];
          values.gallery2 = values.gallery2Link || productGalleryImages[1];
          values.gallery3 = values.gallery3Link || productGalleryImages[2];
        }

        const colorsToSend =
          values.colors?.map((option: any) => ({
            hex: option.value,
          })) || [];

        await newProduct({
          name: values.name,
          brandName: values.brandName,
          description: values.description,
          category: values.category,
          regularPrice: values.regularPrice,
          price: values.price,
          subCategory: values.subCategory,
          stockStatus: values.stockStatus,
          quantityInStock:
            typeof values.quantityInStock === "number"
              ? values.quantityInStock
              : 0,
          unit: values.unit,
          sizes: values.sizes || [],
          colors: colorsToSend,
          storeId: values.storeId,
          tag: values.tag,
          keyFeatures: values.keyFeatures
            .split("\n")
            .filter((el: string) => el.length > 0)
            .map((el: string) => el.trim()),
          mainMaterial: values.mainMaterial,
          productWeight: values.productWeight,
          mainImage: values.mainImageLink || image,
          gallery: [
            values.gallery1Link || values.gallery1,
            values.gallery2Link || values.gallery2,
            values.gallery3Link || values.gallery3,
          ].filter((g) => g != null && g.trim().length > 0 && g !== ""),
          isDraft: values.isDraft,
          isPopular: values.isPopular,
          sku: generateAlphanumericId(6, true),
        }).unwrap();
        toast.success("Product Added Successfully");
        navigate("../products");
      }
    } catch (error: any) {
      toast.error(error.message as ToastContent<unknown>);
    }
  };

  const fetchData = async () => {
    const fetchedData = await getProduct({ id: productId }).unwrap();
    setProduct(fetchedData.Product);
  };

  useEffect(() => {
    if (isEdit && productId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchData();
    }
  }, [isEdit, productId]);

  const mappedProductColors = product?.colors?.map((color: any) => {
    const selectedColor = ProductColors?.find((c) => c.value === color.hex);
    return {
      label: selectedColor?.label,
      value: selectedColor?.hex,
      hex: selectedColor?.hex,
    };
  });

  const onDeleteHandler = async () => {
    try {
      setModalOpen(false);
      await deleteProduct({ id: productId }).unwrap();
      toast.success("Product Deleted Successfully");
      navigate("../products");
    } catch (error) {
      toast.error("Failed to delete product, Please try again later.");
    }
  };

  return (
    <div>
      <BreadCrumb lastName={product?.name} />
      <div className="card">
        {isFetching ? (
          <Loader />
        ) : (
          <>
            <SectionTitle title="Product Details" />
            <Formik
              enableReinitialize
              initialValues={{
                name: product?.name || "",
                brandName: product?.brandName || "",
                description: product?.description || "",
                category: product?.category || "",
                regularPrice: product?.regularPrice || "",
                price: product?.price || "",
                subCategory: product?.subCategory || "",
                stockStatus: product?.stockStatus || "",
                quantityInStock: product?.quantityInStock || 10,
                unit: product?.unit || "",
                sizes: product?.sizes || [],
                colors: mappedProductColors || [],
                storeId: product?.storeId || "",
                mainImage: product?.mainImage || "",
                tag: product?.tag || "",
                gallery1: product?.gallery ? product?.gallery[0] : "",
                gallery2: product?.gallery ? product?.gallery[1] : "",
                gallery3: product?.gallery ? product?.gallery[2] : "",
                gallery: product?.gallery || [],
                keyFeatures:
                  product?.keyFeatures
                    ?.filter((el: string) => el.length > 0)
                    .join("\n") || "",
                mainMaterial: product?.mainMaterial || "",
                productWeight: product?.productWeight || "",
                mainImageLink: null,
                gallery1Link: null,
                gallery2Link: null,
                gallery3Link: null,
                isDraft: product?.isDraft || false,
                isPopular: product?.isPopular || false,
              }}
              validationSchema={ProductSchema}
              onSubmit={async (values) => {
                await productSubmitHandler(values);
                navigate("../products");
              }}
            >
              {({ handleSubmit, values, setFieldValue, dirty, isValid }) => (
                <form
                  data-gtm-form-interact-id="0"
                  className="grid grid-cols-1 items-start gap-5 xl:gap-10"
                  onSubmit={handleSubmit}
                >
                  <ProductForm
                    values={values}
                    setFieldValue={setFieldValue}
                    isEdit={isEdit}
                  />
                  <div className="flex justify-center">
                    <GGButton
                      type="submit"
                      disable={
                        isLoading ||
                        uploadImageIsLoading ||
                        isEditing ||
                        !dirty ||
                        !isValid
                      }
                    >
                      {isLoading || uploadImageIsLoading || isEditing ? (
                        <Spinner />
                      ) : isEdit ? (
                        "Save Changes"
                      ) : (
                        "Add Product"
                      )}
                    </GGButton>
                  </div>
                </form>
              )}
            </Formik>
          </>
        )}
      </div>
      {isFetching ? null : (
        <>
          <Modal
            open={modalOpen}
            setOpen={setModalOpen}
            center
            body={
              <div className="flex flex-col gap-2">
                <h3 className="text-lg font-semibold">Delete Product</h3>
                <p className="text-sm">
                  Are you sure you want to delete this product?, This action is
                  permanent and cannot be undone.
                </p>
                <div className="flex justify-end gap-2">
                  <GGButton
                    type="button"
                    variant="outlined"
                    onClick={() => setModalOpen(false)}
                  >
                    Cancel
                  </GGButton>
                  <GGButton
                    type="button"
                    onClick={onDeleteHandler}
                    loading={isDeleting}
                  >
                    Yes, Delete
                  </GGButton>
                </div>
              </div>
            }
          />
          {isEdit && (
            <DangerCard
              className="mt-8"
              label="Danger zone"
              action={
                <Button
                  onClick={() => setModalOpen(true)}
                  variant="secondary"
                  className="text-red-700"
                  loading={isDeleting}
                  disabled={isDeleting}
                >
                  Delete Product
                </Button>
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default AddProduct;
