import { BreadCrumb, GGButton, Loader, Modal } from "components";
import React, { useEffect, useState } from "react";
import { BiTrash } from "react-icons/bi";
import { FaClipboardCheck } from "react-icons/fa";
import {
  MdCheckCircle,
  MdCloudUpload,
  MdContentCopy,
  MdRefresh,
  MdWarningAmber,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast, type ToastContent } from "react-toastify";
import { useDeleteImageMutation } from "store/api/requests/file/deleteImageAPiSlice";
import {
  useLazyGetFilesQuery,
  useCheckFileStatusMutation,
} from "store/api/requests/file/uploadImageApiSlice";

interface ImageProp {
  id: string;
  filename: string;
  variants: string[];
}

interface ResponseData {
  banners: any[];
  categories: any[];
  numBanners: number;
  numCategories: number;
  numProducts: number;
  numScreens: number;
  numStores: number;
  numSubCategories: number;
  products: any[];
  screens: any[];
  stores: any[];
  subCategories: any[];
}

type DataKeys =
  | "banners"
  | "categories"
  | "products"
  | "screens"
  | "stores"
  | "subCategories";
const getNonEmptyData = (data: ResponseData): string[] => {
  const nonEmptyArrays: DataKeys[] = [];
  if (data?.numBanners > 0) nonEmptyArrays.push("banners");
  if (data?.numCategories > 0) nonEmptyArrays.push("categories");
  if (data?.numProducts > 0) nonEmptyArrays.push("products");
  if (data?.numScreens > 0) nonEmptyArrays.push("screens");
  if (data?.numStores > 0) nonEmptyArrays.push("stores");
  if (data?.numSubCategories > 0) nonEmptyArrays.push("subCategories");

  return nonEmptyArrays;
};

const Media = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [copied, setCopied] = useState("");
  const [selectedItem, setSelectedItem] = useState<ImageProp>();
  const [deleteImage, { isLoading }] = useDeleteImageMutation();
  const [getImages, { isLoading: isImagesLoading, isFetching }] =
    useLazyGetFilesQuery();
  const [checkImageStatus, { isLoading: checkingImageStatus }] =
    useCheckFileStatusMutation();
  const [statusResults, setStatusResults] = useState<ResponseData>({
    banners: [],
    categories: [],
    numBanners: 0,
    numCategories: 0,
    numProducts: 0,
    numScreens: 0,
    numStores: 0,
    numSubCategories: 0,
    products: [],
    screens: [],
    stores: [],
    subCategories: [],
  });
  const [allImages, setAllImages] = useState<ImageProp[]>([]);
  const [searchResults, setSearchResults] = useState<ImageProp[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [refresh, setRefresh] = useState<number | null>(null);
  const navigate = useNavigate();

  const copyToClipboard = (text: string): void => {
    if (!navigator.clipboard) {
      console.error("Clipboard API is not available");
      return;
    }
    setCopied("");
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(text);
        toast.success("Link copied to clipboard", { position: "bottom-right" });
        setTimeout(() => {
          setCopied("");
        }, 3000);
      })
      .catch((err) => {
        console.error("Failed to copy text to clipboard: ", err);
      });
  };

  const onDeleteHandler = async () => {
    try {
      await deleteImage(selectedItem?.variants[0]).unwrap();
      setModalOpen(false);
      setRefresh(Math.random());
      toast.success("Image deleted successfully");
    } catch (error: any) {
      toast.error("An error occurred while deleting image");
    }
  };

  const fetchData = async () => {
    try {
      const fetchedData = await getImages({}).unwrap();
      const fetchedImages = fetchedData?.result?.images || [];
      setAllImages([...fetchedImages]);
    } catch (error: any) {
      toast.error(error.message as ToastContent<unknown>);
    }
  };

  useEffect(() => {
    fetchData();
  }, [refresh]);

  const handleImageSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchTerm(value);

    if (value.length > 0) {
      const results = allImages.filter((img) =>
        img.filename?.toLowerCase()?.includes(value.toLowerCase()),
      );
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  const filteredImages =
    searchTerm.length > 0 && searchResults.length > 0
      ? searchResults
      : allImages;

  const onDeleteClickHandler = async (imageItem: ImageProp) => {
    setSelectedItem(imageItem);
    setModalOpen(true);
    setStatusResults({
      banners: [],
      categories: [],
      numBanners: 0,
      numCategories: 0,
      numProducts: 0,
      numScreens: 0,
      numStores: 0,
      numSubCategories: 0,
      products: [],
      screens: [],
      stores: [],
      subCategories: [],
    });
    const res = await checkImageStatus(imageItem.variants[0]).unwrap();
    setStatusResults(res as ResponseData);
  };

  const getImageUsageLocations = (item: any) => {
    if (
      Array.isArray(statusResults[`${item}` as keyof ResponseData]) &&
      (item === "banners" ||
        item === "categories" ||
        item === "products" ||
        item === "screens" ||
        item === "stores" ||
        item === "subCategories")
    ) {
      return (
        statusResults[`${item}` as keyof ResponseData]
          // @ts-expect-error no error expected
          ?.map((it) => it.name || it?.title)
          .join(", ")
      );
    }
    return null;
  };

  return (
    <div>
      <BreadCrumb />
      <div className="flex justify-between my-3 gap-3">
        <input
          className="w-full md:w-1/2 appearance-none rounded-md bg-[#f5f8fa] box-border border border-[#cbd6e2] py-2 px-3 text-gray-700 leading-tight focus:outline-none mt-2 hover:border-primary focus:border-primary transition-all duration-300"
          placeholder="Search for images"
          type="search"
          value={searchTerm}
          onChange={handleImageSearch}
        />
        <div className="flex gap-2 items-center">
          <button
            onClick={() => {
              navigate("upload");
            }}
            className=" bg-primary hover:bg-primary/80 text-white flex justify-center items-center rounded-full px-4 py-2 text-sm font-semibold mt-2 gap-2 hover:bg-primary-shade transition-all duration-300
            "
          >
            <MdCloudUpload size={20} />
            Upload
          </button>
          <button
            title="Click to refresh images"
            disabled={isFetching}
            onClick={() => setRefresh(Math.random())}
            className="bg-transparent border-2 border-primary text-primary hover:bg-primary/10 flex justify-center items-center rounded-full px-4 py-2 text-sm font-semibold mt-2 gap-2 hover:bg-primary-shade transition-all duration-300"
          >
            <MdRefresh size={20} className={isFetching ? "animate-spin" : ""} />
            <span className="hidden md:block">Refresh</span>
          </button>
        </div>
      </div>
      {allImages.length > 0 ? (
        <div
          className={`mt-8 mb-4 rounded-md p-3 bg-white ${isFetching ? "animate-pulse" : ""}`}
        >
          Displaying {filteredImages.length} images
        </div>
      ) : null}
      {(isFetching || isImagesLoading) && (
        <div className="flex justify-center my-4">
          <Loader />
        </div>
      )}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 ">
        {filteredImages.map((image) => (
          <div key={image.id} className="relative group card">
            <img
              className="h-auto max-w-full rounded-lg"
              src={image.variants[0]}
              alt=""
            />
            <p className="absolute bottom-0 line-clamp-1 text-center left-1/2 -translate-x-1/2 bg-black/80 w-full rounded-b-md py-1 text-white opacity-0 group-hover:opacity-100">
              {image.filename}
            </p>
            <div className="flex items-center justify-center gap-2 opacity-0 group-hover:opacity-100 absolute top-1 right-1">
              <button
                onClick={() => copyToClipboard(image.variants[0])}
                title={copied === image.variants[0] ? "Copied" : "Copy"}
                className="bg-linear hover:bg-linear/80 text-white font-semibold text-sm p-2 rounded-full shadow-md"
              >
                {copied === image.variants[0] ? (
                  <FaClipboardCheck />
                ) : (
                  <MdContentCopy />
                )}
              </button>

              <button
                // eslint-disable-next-line @typescript-eslint/promise-function-async
                onClick={() => onDeleteClickHandler(image)}
                title="Delete"
                className="bg-linear hover:bg-linear/80 text-white font-semibold text-sm p-2 rounded-full shadow-md"
              >
                <BiTrash />
              </button>
            </div>
          </div>
        ))}
      </div>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        center
        body={
          <div className="flex flex-col gap-2">
            <h3 className="text-lg font-semibold">Delete Confirmation</h3>
            <p className="text-sm">
              Are you sure you want to delete this image from media?, This
              action is permanent and cannot be undone.
            </p>
            <div
              className={`rounded-md p-2 ${getNonEmptyData(statusResults).length > 0 || checkingImageStatus ? "bg-signal-error/10 text-signal-error" : ""} ${getNonEmptyData(statusResults).length < 1 && !checkingImageStatus ? "bg-signal-success/10 text-signal-success" : ""} ${checkingImageStatus ? "animate-pulse" : ""}`}
            >
              {checkingImageStatus && (
                <div className="flex flex-col justify-center items-center gap-2">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-primary"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <p>Checking image status, Please wait to delete...</p>
                </div>
              )}
              {!checkingImageStatus && (
                <div>
                  <div className="flex justify-center">
                    {getNonEmptyData(statusResults).length > 0 ? (
                      <MdWarningAmber size={30} />
                    ) : (
                      <MdCheckCircle size={30} />
                    )}
                  </div>
                  {getNonEmptyData(statusResults).length > 0 ? (
                    <p>
                      This image is currently being used in the following
                      locations. Deleting it will remove the image from all of
                      them.
                    </p>
                  ) : (
                    <p>
                      This image is not being used anywhere.You can safely
                      delete it if it&apos;s no longer needed.
                    </p>
                  )}
                  {getNonEmptyData(statusResults).map((item, i) => {
                    return (
                      <div key={i} className="flex gap-2 items-center py-1">
                        <p className="text-sm font-semibold uppercase">
                          {item}
                        </p>
                        <p className="text-sm text-primary uppercase">
                          {
                            statusResults[
                              `num${item.charAt(0).toUpperCase() + item.slice(1)}` as keyof ResponseData
                            ]
                          }
                        </p>
                        {getImageUsageLocations(item)}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="flex justify-end gap-2">
              <GGButton
                type="button"
                variant="outlined"
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </GGButton>
              <GGButton
                type="button"
                onClick={onDeleteHandler}
                loading={isLoading}
                disable={checkingImageStatus}
              >
                Yes, Delete
              </GGButton>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Media;
